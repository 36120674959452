const { createItemCard } = require("./item-card");

const SEARCH_INDEXES = {}, searchForm = document.getElementById("bmod-site-search"), searchResults = document.getElementById("bmod-search-results-list");

function createIndex(documents = [], fields = []) {
	const ref = "id";
	const index = lunr(function () {
		this.ref(ref)
		fields.forEach(field => this.field(field));
		documents.forEach((doc, id) => this.add({ [ref]:id, ...doc }));
	});

	function findRefs(text) {
		try {
			let results = index.search(text)
			return results.map(({ref,score})=>({ref,score}));
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	return function searchStrings(...text) {
		const refs = text.map(findRefs),
		refSet = refs.flat().reduce((refSet,{ref,score})=>{
			if(!refSet[ref]||refSet[ref].score<score) {
				refSet[ref] = {ref,score}
			}
			return refSet;
		},[])
		return Object.values(refSet).map(({score,ref})=>({score,document:documents[ref]}))
	};
}

function getSearchIndex(index) {
	const url = `/api/v1/${index}.json`;
  
	return fetch(url)
	  .then(response => response.json())
	  .then(data => {
		// Process the data here
		return data;
	  })
	  .catch(error => {
		console.error(`Error fetching data from ${url}:`, error);
		return []
	  });
  }




async function updateIndexes() {
	const indexFields = {
        posts: ["type", "title", "author", "categories", "date", "content"],
        servers: ["type", "region", "mode", "gamemode", "map", "ip", "shortmode"],
        members: ["type", "title", "team", "url"]
    };

	async function createAndStoreIndex(type) {
        const currentIndex = SEARCH_INDEXES[type];
        if (!currentIndex) {
            SEARCH_INDEXES[type] = createIndex(await getSearchIndex(type), indexFields[type]);
        }
    }
	const indexTypes = ['posts', 'servers', 'members'];

    // Run background processes for each index type without waiting
    const backgroundProcesses = indexTypes.map(type => createAndStoreIndex(type));
   ; 

	if (Object.keys(SEARCH_INDEXES).length) {
		Promise.all(backgroundProcesses)
    } else {
		await Promise.all(backgroundProcesses)
    }
	return SEARCH_INDEXES;
}

function WikiSearchLink(query) {
	let url = `https://wiki.bmod.tf/index.php?search=${encodeURI(query)}&title=Special%3ASearch&go=Go`;
	return createItemCard({name:"BMod Wiki",tags:"Search the BMod Wiki for "+query,url:url});
}



async function search(text) {
	if(!text) return {}
	const SEARCH_INDEXES = await updateIndexes();

	let indexes = Object.keys(SEARCH_INDEXES)

	const querys = [
		text,
	"*"+text.split(" ").join("*")+"*",
	"*"+text.split("").join("*")+"*",
	...text.split(" "),
	//...text.split(""),
	];

	const results = indexes.reduce((results,index)=>{
		results.push(...SEARCH_INDEXES[index](...querys));
		return results
	},[])

	return results.sort((a,b)=>b.score-a.score).map(a=>a.document);
}

function createHtml(text) {
	const container = document.createElement('div');
	container.innerHTML = text;
	return container.firstChild;
}



searchForm.querySelector("[name=q]").addEventListener("input",async e=>{
	const text = e.target.value;
	searchResults.innerHTML = "";
	const wikiLink = WikiSearchLink(text);
	searchResults.appendChild(wikiLink)
	searchResults.appendChild(createItemCard({
		name: "Loading...",
		tags:"please wait..."
	}))
	let loading = true;
	const results = await search(text);


		for (const result of results) {
			let resultCard = result;
			switch (result.type) {
				case "post":
					resultCard = { 
						image:result["author-icon"],
						name:result.title,
						tags:result.categories,
						// number:"",
						url:result.url
					}
					break;
				case "server":
					resultCard = { 
						image:result.image,
						name:result.map,
						tags:result.shortmode,
						number:result.players,
						url:result.url
					}
					break;
				case "member":
					resultCard = {
						image: result.icon,
						name: result.title,
						tags: result.team,
						url:result.url
					}
					break;
				default:
					continue
			}
			if(loading){
				searchResults.innerHTML = "";
				loading = false
			}

			searchResults.appendChild(createItemCard(resultCard))
		}
		if(!loading)searchResults.appendChild(wikiLink)
	
})
