const { getElement, queryChildren, createLink, createImage } = require("./html");

function capitalize(str="") {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function slugify(str="") {
	return str.replace(/\s+/g, '-').toLowerCase();
}


function createItemCard({ tags, number, image, imageClass, imageAlt, title,  name=title,  nameClass,url }) {
	const itemCard = getElement('<span class="item-card tags number image"></span>');

	if (typeof image !== "undefined") {
		const imageElement = createImage(image||"", { class: `item-card_image${imageClass||""}`.trim(), alt: imageAlt||" " });
		itemCard.appendChild(imageElement);
		//itemCard.classList.add('image');
	}

	if (typeof name !== "undefined") itemCard.appendChild(getElement(`<strong class="item-card_name ${nameClass||""}" data-skeleton-width="${Math.round(5 + Math.random() * 5)}">${capitalize(name||"")}</strong>`));

	if (typeof tags !== "undefined") {
		//itemCard.classList.add('tags');
		const tagsContainer = getElement(`<div class="item-card_tags" data-skeleton-width="${Math.round(2 + Math.random() * 2)}"></div>`);
		if(Array.isArray(tags))
		tags.forEach((tag, index) => {
			const tagElement = getElement(`<span class="tag-${slugify(tag||"")}">${capitalize(tag||"")}</span>`);
			index === 0 && index === tags.length - 1
				? tagsContainer.appendChild(document.createTextNode(capitalize(tag||"")))
				: tagsContainer.appendChild(tagElement);
		});
		else if(tags) tagsContainer.textContent = tags||""
		itemCard.appendChild(tagsContainer);
	}

	if (typeof number !== "undefined") {
		//itemCard.classList.add('number');
		itemCard.appendChild(getElement(`<div class="item-card_number">${number||""}</div>`));
	}

	queryChildren(itemCard,{
		nameElement:".item-card_name",
		imageElement:".item-card_image",
		tagsContainer:".item-card_tags",
		numberElement:".item-card_number"
	})

	if (typeof url !== "undefined") {
		const itemLink = createLink(url,{}, itemCard)
		itemLink.itemCard = itemCard;
		return itemLink
	}


	return itemCard;
}

module.exports ={createItemCard,slugify,capitalize}